import { format } from "date-fns";
import { buffer, concat, filter, fromPromise, interval, map, merge, pipe, Source } from "wonka";
import { Override } from "../types";
import { dateToStr, strToDate } from "../utils/dates";
import { removeEmpty } from "../utils/objects";
import { deserialize, upsert } from "../utils/wonka";
import { SubscriptionType } from "./adaptors/ws";
import {
  AssistCompleted,
  AssistPlanned,
  Event as EventDto,
  EventType as EventTypeDto,
  Priority as PriorityDto,
  ProjectInclude as ProjectIncludeDto,
  ReclaimEventActions as ReclaimEventActionsDto,
} from "./client";
import { AssistDetails, AssistStatus, AssistType, Category, EventColor, PrimaryCategory } from "./EventMetaTypes";
import { dtoToProject, IncludeProject, Project, projectToDto, Smurf } from "./Projects";
import { NotificationKeyStatus, TransformDomain } from "./types";

export enum EventResponseStatus {
  None = "None",
  Organizer = "Organizer",
  Accepted = "Accepted",
  Declined = "Declined",
  TentativelyAccepted = "TentativelyAccepted",
  NotResponded = "NotResponded",
}

export enum ReclaimEventType {
  User = "USER",
  Sync = "SYNC",
  HabitAssignment = "HABIT_ASSIGNMENT",
  OneOnOneAssignment = "ONE_ON_ONE_ASSIGNMENT",
  TaskAssignment = "TASK_ASSIGNMENT",
  ConfBuffer = "CONF_BUFFER",
  TravelBuffer = "TRAVEL_BUFFER",
  Unknown = "UNKNOWN",
}

export enum EventStatus {
  Draft = "DRAFT",
  Published = "PUBLISHED",
  Cancelled = "CANCELLED",
  Rescheduling = "RESCHEDULING",
}

export type ReclaimEventActions = ReclaimEventActionsDto;

export type EventKey = string;

export type Event = Override<
  EventDto,
  {
    // primary key: *THE* PK for an event is: `event.key`
    readonly key: EventKey;
    // deprecated: `event.id` is legacy garbage and should not be used
    readonly id: string;
    readonly reclaimEventType: ReclaimEventType;
    readonly status?: EventStatus;
    readonly category: Category;
    readonly projects?: Project[];
    readonly assist?: AssistDetails;

    eventId: string;
    eventStart: Date;
    eventEnd: Date;
    updated?: Date;
    type?: null;
    subType?: null;
    meetingType?: null;
    // categoryOverride is a write only field to set an overide... use this ONLY to know if the category has been overridden
    categoryOverride?: Category;
    color: EventColor;
    chunks: number;
    smurf?: Smurf;

    actions?: ReclaimEventActions;
  }
>;

export function dtoToEvent(dto: EventDto): Event {
  const assist: AssistDetails | undefined = !!dto.assist
    ? {
        ...dto.assist,
        type: !!dto.assist.type ? AssistType.get(dto.assist.type) : undefined,
        status: dto.assist.status as unknown as AssistStatus,
      }
    : undefined;

  return {
    ...dto,
    assist,
    id: dto.id as unknown as string,
    key: dto.key as unknown as string,
    eventId: dto.eventId || "-",
    reclaimEventType: dto.reclaimEventType as unknown as ReclaimEventType,
    status: dto.status as unknown as EventStatus,
    projects: dto.projects?.map(dtoToProject),
    eventStart: strToDate(dto.eventStart) as Date,
    eventEnd: strToDate(dto.eventEnd) as Date,
    updated: strToDate(dto.updated),
    type: null,
    subType: null,
    meetingType: null,
    category: Category.get(dto.category as unknown as string) || PrimaryCategory.TeamMeeting,
    categoryOverride: Category.get(dto.categoryOverride as unknown as string),
    color: !!dto.color ? EventColor.get(dto.color) : EventColor.Auto,
    chunks:
      !!dto.eventEnd && !!dto.eventStart
        ? ((strToDate(dto.eventEnd) as Date).getTime() - (strToDate(dto.eventStart) as Date).getTime()) / 900000
        : 0,
    smurf: dto.smurf ? Smurf[dto.smurf] : undefined,
  };
}

export function eventToDto(event: Partial<Event>): EventDto {
  return removeEmpty({
    ...event,
    chunks: undefined,
    projects: event.projects?.map(projectToDto),
    eventStart: dateToStr(event.eventStart),
    eventEnd: dateToStr(event.eventEnd),
    updated: dateToStr(event.updated),
    category: event.category?.toJSON(),
    categoryOverride: event.categoryOverride?.toJSON(),
    color: (EventColor.Auto === event.color ? null : event.color?.toJSON()) as EventDto["color"],
  }) as EventDto;
}

const EventsSubscription = {
  subscriptionType: SubscriptionType.Events,
};

export class EventsDomain extends TransformDomain<Event, EventDto> {
  resource = "Event";
  cacheKey = "events";
  pk = "key";

  public deserialize = dtoToEvent;
  public serialize = eventToDto;

  watchWs$: Source<Event[]> = pipe(
    merge([
      this.ws.subscription$$(EventsSubscription),
      // Events can be included in assist payloads
      this.ws.subscription$$({ subscriptionType: SubscriptionType.AssistPlanned }),
      this.ws.subscription$$({ subscriptionType: SubscriptionType.AssistCompleted }),
    ]),
    filter((envelope) => !!envelope.data),
    map((envelope) => {
      return envelope.type === SubscriptionType.Events
        ? (envelope.data as EventDto[])
        : ((envelope.data as AssistPlanned | AssistCompleted).events as EventDto[]);
    }),
    buffer(interval(10)),
    map((buffered) => buffered.flat()),
    deserialize(this.deserialize)
  );

  watchAll$ = pipe(
    merge([this.upsert$, this.watchWs$]),
    map((items) => this.patchExpectedChanges(items))
  );

  watch$$ = (start?: Date, end?: Date) => {
    const subscription = {
      subscriptionType: SubscriptionType.Events,
      startTime: start,
      endTime: end,
    };

    return pipe(
      merge([
        this.upsert$,
        pipe(
          merge([
            this.ws.subscription$$(subscription),
            // Events can be included in assist payloads
            this.ws.subscription$$({ subscriptionType: SubscriptionType.AssistPlanned }),
            this.ws.subscription$$({ subscriptionType: SubscriptionType.AssistCompleted }),
          ]),
          filter((envelope) => !!envelope.data),
          map((envelope) => {
            return envelope.type === SubscriptionType.Events
              ? (envelope.data as EventDto[])
              : ((envelope.data as AssistPlanned | AssistCompleted).events as EventDto[]);
          }),
          buffer(interval(10)),
          map((buffered) => buffered.flat()),
          deserialize(this.deserialize)
        ),
      ]),
      map((items) => this.patchExpectedChanges(items))
    );
  };

  list$$ = (
    start?: Date,
    end?: Date,
    limit?: number,
    type?: PrimaryCategory[],
    calendarId?: number,
    smurf?: PriorityDto[],
    include?: IncludeProject,
    sourceDetails?: boolean,
    habitIds?: number[]
  ) => {
    return pipe(
      fromPromise(
        this.client.events.list(start, end, limit, type, calendarId, smurf, include, sourceDetails, habitIds)
      ),
      map((items) => this.patchExpectedChanges(items))
    );
  };

  listAndWatch$$ = (
    start?: Date,
    end?: Date,
    limit?: number,
    type?: PrimaryCategory[],
    calendarId?: number,
    smurf?: PriorityDto[],
    projects?: IncludeProject,
    sourceDetails?: boolean,
    habitIds?: number[],
    statuses: EventStatus[] = [EventStatus.Published]
  ) => {
    return pipe(
      concat<Event[] | null>([
        this.list$$(start, end, limit, type, calendarId, smurf, projects, sourceDetails, habitIds),
        this.watch$$(start, end),
      ]),
      upsert((e) => this.getPk(e)),
      map((events: Event[]) => {
        return events
          .filter(
            (e) =>
              (!start || !(e.eventStart <= start && e.eventEnd <= start)) &&
              (!end || !(e.eventStart > end && e.eventEnd > end))
          )
          .filter((e) => !!e.status && statuses.includes(e.status))
          .filter((e) => !type || (!!e.type && type.includes(e.type)))
          .filter((e) => !habitIds || (!!e.assist?.dailyHabitId && habitIds?.includes(e.assist.dailyHabitId)));
      })
    );
  };

  watchId$$ = (key: string) => {
    return pipe(
      concat([fromPromise(this.get(key).then(r => !!r ? [r] : [])), this.watchAll$]),
      map((items) => items?.find((i) => i.key === key))
    );
  };

  list = this.deserializeResponse(
    (
      start?: Date,
      end?: Date,
      limit?: number,
      type?: PrimaryCategory[],
      calendarId?: number,
      smurf?: PriorityDto[],
      include?: IncludeProject,
      sourceDetails?: boolean,
      habitIds?: number[]
    ) => {
      return this.api.events.query({
        start: start ? format(start, "yyyy-MM-dd") : undefined,
        end: end ? format(end, "yyyy-MM-dd") : undefined,
        type: !!type ? type.map((t) => t.toJSON() as EventTypeDto) : undefined,
        calendar: calendarId,
        includeProjects: include as unknown as ProjectIncludeDto,
        sourceDetails: sourceDetails,
        habitIds,
      });
    }
  );

  listPersonal = this.deserializeResponse((start?: Date, end?: Date, limit?: number) => {
    return this.api.events.getPersonal1({
      start: start ? format(start, "yyyy-MM-dd") : undefined,
      end: end ? format(end, "yyyy-MM-dd") : undefined,
      limit,
    });
  });

  get = this.deserializeResponse((eventId: string, calendarId?: number, sourceDetails?: boolean) => {
    const query = { sourceDetails };
    return undefined !== calendarId
      ? this.api.events.getForCalendar(calendarId, eventId, query)
      : this.api.events.get1(eventId, query);
  });

  patch = this.deserializeResponse((calendarId: number, eventId: string, patch: Partial<Event>, assist?: boolean) => {
    const payload = { ...patch, eventId: undefined };
    const notificationKey = this.generateUid("patch", `${calendarId}/${eventId}`);

    this.expectChange(notificationKey, eventId, patch, assist);

    return this.api.events
      .patch1(calendarId, eventId, this.serialize(payload), { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  });

  adjustTravelTime = (
    calendarId: number,
    eventId: string,
    type: typeof AssistType.PreTravel | typeof AssistType.PostTravel,
    duration: number
  ) => {
    const notificationKey = this.generateUid("adjustTravelTime", `${calendarId}/${eventId}`);

    this.addNotificationKey(notificationKey);

    return this.api.events
      .adjustTravelTime(calendarId, eventId, type.key, { duration, notificationKey }, {})
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };

  adjustConfBuffer = (calendarId: number, eventId: string, duration: number) => {
    const notificationKey = this.generateUid("adjustConfBuffer", `${calendarId}/${eventId}`);

    this.addNotificationKey(notificationKey);

    return this.api.events
      .adjustConferenceBuffer(calendarId, eventId, { duration, notificationKey }, {})
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };

  updateRsvp = this.deserializeResponse(
    (calendarId: number, eventId: string, eventKey: string, status: EventResponseStatus) => {
      const notificationKey = this.generateUid("updateRsvp", eventKey);

      this.expectChange(notificationKey, eventKey, { rsvpStatus: status });

      return this.api.events
        .patch1(calendarId, eventId, this.serialize({ rsvpStatus: status }), { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    }
  );

  sendTestEvent() {
    return this.api.events.sendTestEvent({});
  }
}
